import React from 'react'

// Components
import Layout from '../components/layout.js'
import SEO from '../components/seo.js'

// SVGs

export default () => (
    <Layout className="page-404">
        <SEO title="404" />
        <section className="padding-y-10 hero">
            <div className="container">
                <div className="row">
                    <div className="col-10 offset-1 center">
                        <img
                            src="https://d2jotzgct8q460.cloudfront.net/svgs/Peep-404.svg"
                            alt="Peep 404 SVG"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 center">
                        <h1 className="margin-bottom-3">
                            Oops! This is awkward.
                        </h1>
                        <h2 className="weight-500">
                            Looks like we couldnt find the page you’re looking
                            for.
                        </h2>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)
